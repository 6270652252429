import React from 'react'
import Layout from '@components/Layout'
import { Link } from 'gatsby'
import { Seo } from '@components/Seo'
import PageTitle from '@components/PageTitle'
import { TitleParagraph } from '@global'

const ExploreIndex = () => (
  <Layout>
    <Seo title="Exploring Sowerby Bridge | The Town and Business Guide For Residents and Visitors" />
    <PageTitle title="Exploring Sowerby Bridge" />
    <TitleParagraph>Please select from one of the links below</TitleParagraph>
    <ul>
      <li>
        <Link to="/explore/calderhebblenavigation">Calder and Hebble</Link>
      </li>
      <li>
        <Link to="/explore/historicinterest">Historic Interests</Link>
      </li>
      <li>
        <Link to="/explore/nature">Nature and Wildlife</Link>
      </li>
      <li>
        <Link to="/explore/thewharf">The Wharf</Link>
      </li>
      <li>
        <Link to="/explore/walkingandcycling">Walking and Cycling</Link>
      </li>
    </ul>
  </Layout>
)

export default ExploreIndex
